<template>
  <div class="distributorOrder">
    <van-nav-bar
      title="分享的订单"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <!-- <div class="order" v-for="it in orderList" :key="it.id">
      <div class="name">实际支付：{{it.actualPrice}}元</div>
      <div class="phone">订单编号：{{it.orderNo}}</div>
      <div class="phone">下单时间：{{it.gmtCreate | parseTime}}</div>
    </div> -->

    <div class="order">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        offset:50
        
      >
      <div class="item_order" v-for="item in orderList" :key="item.id">
        <van-card
          :num="item.skuList[0].num"
          :title="item.skuList[0].spuTitle"
          :thumb="item.skuList[0].img"
          :desc="item.skuList[0].title"
          class="order_card"
        >
          <span slot="price" style="color: #7d7e80"
            >￥{{ (item.skuList[0].price / 100).toFixed(2) }}</span
          >
        </van-card>

        <p class="common_desc">
          共{{ item.skuList[0].num }}件商品&emsp;合计：￥{{
            (item.actualPrice / 100).toFixed(2)
          }}
        </p>
        <p class="common_desc">下单时间：{{ item.gmtCreate  | parseTime  }}</p>
      </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      orderList: "",
      loading: false,
      finished: false,
      error: false,
      pageNo: 1,
      immediate: false,
    };
  },
  // filters: {
  //   gmtCreateFilter(gmtCreate) {
  //     return this.parseTime(gmtCreate)
  //   }
  // },
  created() {
    // this.getCommissionOrder();
  },
  mounted() {},
  methods: {
    getCommissionOrder() {
      console.log(this.pageNo);
      
      if (this.pageNo > this.totalPageNo) {
        this.loading = false;
        return (this.finished = true);
      }
      let storeId = localStorage.getItem("storeId");
      this.$api.distributor
        .getCommissionOrder({
          // accessToken: localStorage.getItem("token"),
          storeId: storeId != "null" && storeId ? storeId : 0,
          userId: localStorage.getItem("customerId"),
          pageNo: this.pageNo,
        })
        .then((res) => {
          console.log("展示分销员分享出去的订单", res);
          if (res.errno == 200) {
            this.loading = false; // 关闭上拉加载中
            this.totalPageNo = res.data.totalPageNo;
            if (this.pageNo === 1) {
              this.orderList = res.data.items;
            } else {
              this.orderList = this.orderList.concat(res.data.items);
            }
            this.pageNo++;
            
          } else {
            this.$toast(res.errmsg);
            // this.error = true;
          }
        });
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.getCommissionOrder();
      }, 2000);
    },
    onClickLeft() {
      this.$router.back();
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.distributorOrder {
  width: 100vw;
  height: 100vh;
}
.order {
  
}
.item_order {
  width: 92vw;
  margin: 10px auto;
  padding: 0.32rem 0;
  background: #fff;
  font-size: 0.24rem /* 12/50 */;
  border-radius: 0.2rem /* 10/50 */;
  box-sizing: border-box;
}
.order_card {
  background: #fff;
  border-radius: 0.2rem /* 10/50 */;
}
.common_desc {
  text-align: right;
  padding: 0 0.32rem /* 16/50 */;
  line-height: 0.56rem;
  font-size: 0.24rem;
}
</style>
